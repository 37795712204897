import * as React from "react";
const ServiceCustomAI = (props) => (
  <svg
    width={33}
    height={33}
    viewBox="0 0 33 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.2676 0.953579C6.20283 1.01835 6.15749 1.17381 6.15749 1.29688C6.15749 1.4912 6.23522 1.59484 6.77284 2.13246L7.38818 2.7478L4.31792 2.78019C1.41607 2.81258 1.24119 2.81905 1.11164 2.93565C0.936752 3.09758 0.923797 3.4927 1.09221 3.64168C1.20232 3.73884 1.51971 3.75179 4.35031 3.75179H7.48534L6.81818 4.41896C6.22874 5.01487 6.15749 5.10555 6.15749 5.31283C6.15749 5.60431 6.31942 5.75977 6.61738 5.75977C6.81818 5.75977 6.94125 5.65613 7.94523 4.63919C8.5541 4.03032 9.07229 3.47326 9.0982 3.40849C9.17593 3.17531 8.93627 2.85792 7.91932 1.85393C6.93477 0.888806 6.87647 0.836987 6.63033 0.836987C6.46192 0.836987 6.33238 0.875851 6.2676 0.953579Z"
      fill="white"
    />
    <path
      d="M26.0299 1.85393C24.9482 2.92269 24.7798 3.14292 24.8446 3.38258C24.8705 3.46031 25.3887 4.03032 25.9976 4.63919C27.0275 5.67556 27.1246 5.75977 27.3448 5.75977C27.6363 5.75977 27.7918 5.59783 27.7918 5.29987C27.7918 5.11203 27.7076 5.00192 27.1311 4.41896L26.4639 3.75179H29.6119C32.5785 3.75179 32.7664 3.74531 32.8635 3.6352C33.019 3.46031 33.006 3.08462 32.8376 2.93565C32.7081 2.81905 32.5332 2.81258 29.6313 2.78019L26.5611 2.7478L27.1764 2.13246C27.727 1.58188 27.7918 1.49768 27.7918 1.28392C27.7918 0.985966 27.6298 0.836987 27.306 0.836987C27.0793 0.836987 27.0015 0.901761 26.0299 1.85393Z"
      fill="white"
    />
    <path
      d="M12.8097 2.84507C11.9935 2.98757 11.3458 3.75189 11.3393 4.57452V4.91782H10.5491C10.1086 4.91782 9.64871 4.9502 9.51269 4.98259C9.26007 5.05384 8.83905 5.46839 8.78075 5.71453C8.76132 5.79226 8.74836 6.53715 8.76132 7.35977C8.78075 8.75888 8.78723 8.86899 8.91677 9.0374C9.22121 9.45195 9.31837 9.48434 10.3742 9.50377L11.3393 9.5232V11.0648C11.3393 12.7943 11.3782 13.0015 11.7733 13.4614C11.8964 13.6104 12.149 13.8112 12.3368 13.9084L12.6672 14.0833H16.9746H21.282L21.6124 13.9084C21.8002 13.8112 22.0528 13.6104 22.1759 13.4614C22.571 13.0015 22.6099 12.7943 22.6099 11.0648V9.5232L23.575 9.50377C24.6502 9.48434 24.7474 9.44548 25.0583 8.99854C25.1943 8.79127 25.2008 8.73297 25.2008 7.26909C25.2008 5.62385 25.1879 5.56555 24.8316 5.22225C24.566 4.96963 24.3134 4.91782 23.4066 4.91782H22.6099V4.61986C22.6099 3.93326 22.2342 3.33087 21.6059 3.007L21.2237 2.81268L17.1754 2.79973C14.9472 2.79973 12.9845 2.81916 12.8097 2.84507ZM21.2043 3.90087C21.3079 3.96565 21.444 4.10815 21.5152 4.22474C21.6383 4.42554 21.6383 4.55508 21.6253 8.52569L21.6059 12.6129L21.444 12.7878C21.1136 13.1505 21.1849 13.144 16.9746 13.144C12.7643 13.144 12.8356 13.1505 12.5052 12.7878L12.3433 12.6129L12.3239 8.55808C12.3109 5.70805 12.3303 4.44497 12.3822 4.32838C12.4793 4.08224 12.719 3.87496 12.9716 3.81667C13.0882 3.78428 14.9472 3.76485 17.1041 3.77133C20.7703 3.78428 21.0424 3.79076 21.2043 3.90087ZM11.3393 7.21727V8.5516L10.5491 8.53217L9.75235 8.51274L9.73292 7.25614C9.71996 6.29101 9.7394 5.98658 9.79769 5.94124C9.84303 5.91533 10.2122 5.88942 10.6138 5.88942H11.3393V7.21727ZM24.1968 7.21727V8.51274L23.4066 8.53217L22.6099 8.5516V7.21727V5.88294L23.4066 5.90237L24.1968 5.9218V7.21727Z"
      fill="white"
    />
    <path
      d="M14.0598 6.21962C13.6582 6.38155 13.4121 6.77019 13.4121 7.24951C13.4121 7.89077 13.8072 8.28589 14.455 8.28589C15.297 8.28589 15.7958 7.47622 15.4071 6.73133C15.161 6.25848 14.5456 6.0253 14.0598 6.21962Z"
      fill="white"
    />
    <path
      d="M18.9826 6.29101C18.594 6.4918 18.3802 6.88692 18.4191 7.33386C18.5098 8.33785 19.8765 8.66171 20.4011 7.8067C20.4983 7.65773 20.5372 7.48284 20.5372 7.2367C20.5372 6.40112 19.7275 5.90884 18.9826 6.29101Z"
      fill="white"
    />
    <path
      d="M15.3295 10.3651C15.1157 10.5335 15.0963 10.8768 15.2906 11.0711C15.4137 11.1942 15.5044 11.2007 16.9747 11.2007C18.4451 11.2007 18.5358 11.1942 18.6588 11.0711C18.8532 10.8768 18.8337 10.5335 18.62 10.3651C18.458 10.2356 18.3674 10.2291 16.9747 10.2291C15.5821 10.2291 15.4914 10.2356 15.3295 10.3651Z"
      fill="white"
    />
    <path
      d="M2.09628 12.25C0.606489 13.7333 0.612966 13.5195 2.06389 14.9705C2.75697 15.6635 3.21038 16.0651 3.32697 16.0975C3.6055 16.1623 3.85164 15.955 3.87755 15.6376C3.89698 15.4044 3.87107 15.3591 3.2622 14.7502L2.62742 14.1155H5.63938C7.29758 14.1155 8.71612 14.096 8.80032 14.0766C9.11124 13.9924 9.20192 13.5325 8.95578 13.254C8.8651 13.1503 8.63191 13.1439 5.7495 13.1439H2.6339L3.2622 12.5026C3.85164 11.9067 3.8905 11.8484 3.8905 11.6022C3.8905 11.2978 3.72857 11.1359 3.41118 11.1359C3.24924 11.1359 3.04197 11.3108 2.09628 12.25Z"
      fill="white"
    />
    <path
      d="M30.2015 11.2395C30.0914 11.3108 30.059 11.4014 30.059 11.6022C30.059 11.8484 30.0979 11.9067 30.6873 12.5026L31.3156 13.1439H28.2C25.3176 13.1439 25.0844 13.1503 24.9937 13.254C24.7476 13.5325 24.8383 13.9924 25.1492 14.0766C25.2334 14.096 26.6519 14.1155 28.3101 14.1155H31.3221L30.6873 14.7502C30.0785 15.3591 30.0526 15.4044 30.072 15.6376C30.0979 15.955 30.344 16.1623 30.6226 16.0975C30.7392 16.0651 31.1926 15.6635 31.8921 14.9705C33.3366 13.5195 33.343 13.7333 31.8533 12.25C30.6549 11.0581 30.5513 10.9934 30.2015 11.2395Z"
      fill="white"
    />
    <path
      d="M15.31 16.2271C12.0195 16.7065 9.11763 18.5525 7.22625 21.3702C6.79227 22.0179 6.24817 23.119 5.97612 23.9028C5.5551 25.1011 5.25714 26.9666 5.33487 27.9447C5.51623 30.3607 8.4699 32.0513 13.6064 32.6861C14.9602 32.8545 18.9956 32.8545 20.3429 32.6861C25.3045 32.0707 28.1934 30.4967 28.5885 28.2038C28.6857 27.6402 28.5691 26.2346 28.3424 25.2177C27.8177 22.8923 26.749 21.0139 24.9871 19.3168C23.2253 17.6263 21.023 16.564 18.6005 16.2271C17.726 16.1041 16.1455 16.1041 15.31 16.2271ZM19.0604 17.283C20.8934 17.6781 22.4545 18.4424 23.8795 19.6407C26.166 21.558 27.591 24.5311 27.6234 27.4265C27.6299 28.1843 27.6234 28.2167 27.4161 28.6377C26.6453 30.2052 23.7564 31.3647 19.6304 31.7663C18.3155 31.8958 15.6338 31.8958 14.3189 31.7663C10.1281 31.3582 7.24568 30.1858 6.49431 28.573C6.33238 28.2297 6.31942 28.1455 6.3259 27.4265C6.33238 26.999 6.3842 26.3577 6.44897 26.0015C7.24568 21.4349 10.7888 17.9436 15.4007 17.1923C16.2427 17.0562 18.2248 17.1081 19.0604 17.283Z"
      fill="white"
    />
  </svg>
);
export default ServiceCustomAI;
